export default {
  blue: '#0077ff',
  blue2: '#6688dd',
  red: '#ff5577',
  green: '#559911',
  light: '#CFD8E5',
  gray2: '#B2B8CD',
  gray: '#9498B9',
  dark: '#585B7B',
  darker: '#484B6B',

  lightgreen: '#90ec90',
  mediumgreen: '#59d28f',
  darkergreen: '#51c082',

}