import React from "react";
import styled from "styled-components";
import {mobileAdTitle, adLink} from './adsinfo'; 
import { FormattedMessage } from "react-intl";
// import C from "../../colors";

const Mobile = styled.a`
  @media (max-width: 850px) {
    #mobile-green-container {
      background: -webkit-linear-gradient(79deg, #D966FF, #D966FF 20%, #6f6fff);
      color: white;
      margin-left: -6.2%;
      margin-right: -6.2%;
      overflow: hidden !important;
      padding-top: 0.8rem;
      padding-bottom: 0.9rem;
      position: fixed;
      bottom: 0px;
      width: 120%;
      width: 102vw !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      font-size:0.8rem;
      padding-left: 13px;
      padding-right: 20px;
      font-weight: 700;
      text-align: center;
    }
  }

  @media (min-width: 850px) {
    display: none;
  }
`;

class MobileAds extends React.Component {
//   constructor() {
//     super();
//   }

  render() {
    return (
      <Mobile
        href={adLink}
        target="_blank"
        rel="nofollow"
        className="mobileadvertisement"
      >
        <div id="mobile-green-container">
          <a><FormattedMessage id="mobile-ad-title" /></a>
        </div>
      </Mobile>
    );
  }
}

export default MobileAds;
