import React from "react";
import styled from "styled-components";
import C from "../../../colors";
import {adTitle, adDescription, endorsement, callToAction, adLink} from './adsinfo'; 
import { FormattedMessage } from "react-intl";

const SideAd = styled.div`
  /* sidebar */

  .sidebar--feature-island {
    position: absolute !important;
    top: 0%;
    bottom: 0;
    left: 98%;
  }

  @media (max-width: 850px) {
    .sticky-parent {
      display: none !important;
    }
  }

  .sidebar {
    width: 37%;
    margin: 0 20px 0 30px;
    padding-top: 10px;
    position: relative;
    z-index: 10;
  }

  .sidebar {
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  }

  .sticky-parent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  aside {
    display: block;
  }

  .sticky-top-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .sticky-top-wrap .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    bottom: 0;
  }

  /* .sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0 !important;
} */

  .sidebar-widget--event,
  .sidebar-widget--extra-crunch {
    font-family: aktiv-grotesk, sans-serif;
    letter-spacing: -0.05em;
  }

  @media (min-width: 1100px) {
    .sidebar-widget {
      margin-left: 15%;
      width: 85%;
    }
  }

  @media (min-width: 450px) {
    .sidebar-widget {
      margin: 10px 0;
    }
  }
  .sidebar-widget {
    margin: 20px;
  }
  .sidebar-widget {
    margin-bottom: 25px;
  }

  /* gradient text (ad) */

  a:hover {
    text-decoration: none;
  }

  .sidebar-widget--event__title {
    font-weight: 800;
    background: -webkit-linear-gradient(79deg, #D966FF, #D966FF 20%, #6f6fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .sidebar-widget--event__subtitle {
    color: ${C.darker};
    font-weight: 800;
    line-height: 1.1;
    letter-spacing: -1.7px;
    font-size: 1.7rem;
  }

  .sidebar-widget--event__info {
    line-height: 1.2;
    margin-top: 0.9rem;
    margin-bottom: 1.2rem;
  }
  .sidebar-widget--event__location {
    font-weight: 800;
    color: #6f6fff;
    font-size: 16px;
    font-size: 1rem;
  }

  .sidebar-widget .button {
    font-weight: 800;
    margin: 0 0 10px;
    padding: 7px 20px;
    font-size: 14px;
    font-size: 0.875rem;
  }

  .button--secondary.button--green-gradient {
    background: -webkit-linear-gradient(79deg, #D966FF, #D966FF 20%, #6f6fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .button--secondary.button--green-gradient {
    color: #00a562;
    border-color: #00a562;
    border-image-source: linear-gradient(79deg, #D966FF, #D966FF 20%, #6f6fff);
    border-image-slice: 1;
  }

  .button--secondary {
    border: 2px solid transparent;
  }

  /* gradient text (ad) */

  /* end sidebar */
`;

class SideAds extends React.Component {
//   constructor() {
//     super();
//   }

  render() {
    return (
      <SideAd>
        <aside className="sidebar sidebar--feature-island sidebar--sticky-main sticky-parent ">
          <div className="sticky-top-wrap">
            <div className="ad-unit sticky">
              <div className="sidebar-widget sidebar-widget--event">
                <div className="gradient-text gradient-text--green-gradient">
                  <a href={adLink} target="_blank" rel="noopener noreferrer">
                    <h2 className="sidebar-widget--event__title gradient-text gradient-text--green-gradient">
                    <FormattedMessage id="desktop-ad-title" />
                    </h2>
                  </a>
                </div>
                <a rel="noopener noreferrer" href={adLink} target="_blank">
                  <span className="sidebar-widget--event__subtitle">
                  <FormattedMessage id="desktop-ad-description" />
                  </span>
                </a>
                <p className="sidebar-widget--event__info">
                  <span className="sidebar-widget--event__location">
                  <FormattedMessage id="desktop-ad-endorsement" />
                  </span>
                </p>
                <a
                  className="button button--green-gradient button--secondary"
                  href={adLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="desktop-ad-cta" />
                </a>
              </div>
            </div>
          </div>
        </aside>
      </SideAd>
    );
  }
}

export default SideAds;
