import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import simpleTransformLocale from "../components/LocaleTransform/simpleTransformLocale"

import { Link } from "gatsby";

import locales from "../locales";

const LocalizedLink = ({ to, locale , ...props }) => {
  let currentLocale
  if (locales[locale] === undefined ){
    currentLocale = locales["en-US"].default
  } else {
    currentLocale = locales[locale].default
  }
  const path = currentLocale
    ? to
    : `/${simpleTransformLocale(locale)}/${to}`;

  return <Link {...props} to={path} />;
};

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
};

export default injectIntl(LocalizedLink);
