import React from "react"
import styled, { css } from "styled-components"
import { Search } from "styled-icons/fa-solid/Search"
import { Algolia } from "styled-icons/fa-brands/Algolia"
import C from "../../../colors"

export const Root = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1em;
`

export const SearchIcon = styled(Search)`
  width: 1.2em;
  pointer-events: none;
  color: ${C.dark} !important;
`

export const Input = styled.input`
  outline: none;
  border: none;
  font-size: 1.2em;
  background: transparent;
  transition: ${props => props.theme.shortTrans};
  // border-radius: ${props => props.theme.smallBorderRadius};
  border-radius: 4px;
  ${props => (props.collapse ? collapse : expand)};
`

const focus = css`
  background: white;
  color: ${props => props.theme.darkBlue};
  cursor: text;
  width: 9.5em;
  border: 1px solid ${C.gray};
  padding: 4px 10px;
  + ${SearchIcon} {
    // color: ${props => props.theme.darkBlue};
    margin-right: 0.3em;
  }
  ${Input} {
    border: 1px solid ${C.gray};
  }
`

const collapse = css`
  width: 0;
  cursor: pointer;
  color: ${props => props.theme.lightBlue};
  + ${SearchIcon} {
    color: inherit;
  }
  ${props => props.focus && focus}
  margin-left: ${props => (props.focus ? `-2em` : `-1em`)};
  padding-left: ${props => (props.focus ? `2em` : `1em`)};
  ::placeholder {
    color: ${C.gray2};
  }
`

const expand = css`
  // background: ${props => props.theme.veryLightGray};
  width: 1em;
  margin-left: -1.6em;
  padding-left: 1.6em;
  border-bottom: 1px solid;
  // + ${SearchIcon} {
  //   margin: 0.3em;
  // }
`

export const Form = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`

export const HitsWrapper = styled.div`
  display: ${props => (props.show ? `grid` : `none`)};
  max-height: 80vh;
  overflow: auto;
  z-index: 2000;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5em);
  width: 80vw;
  max-width: 30em;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 1.2em 1.4em;
  background: white;
  text-align: left;
  border-radius: 6px;

  > * + * {
    padding-top: 1em !important;
    border-top: 2px solid ${C.gray2};
  }
  li + li {
    margin-top: 1.2em;
    padding-top: 1.2em;
    border-top: 1px solid ${C.light};
  }
  * {
    margin-top: 0;
    padding: 0;
  }
  ul {
    list-style: none;
  }
  mark {
    color: ${props => props.theme.lightBlue};
    background: ${props => props.theme.darkBlue};
    background-color:#e0feb1;
  }
  header {
    display: flex;
    justify-content: space-between;
    color: ${C.gray};
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 8px;
    margin-top:8px
  }
  h3 {
    font-weight: 400;
    margin: 0 0;
    font-size:1rem;
  }
  h4 {
    font-weight: 400;
    margin-bottom: 0;
    font-size: 1rem;
    margin-bottom: 2px;

    :hover{
      color: ${C.mediumgreen};
      text-decoration: underline;
    }
  }
  .ais-Hits-item {
    font-weight: 400;
    font-size: 13px;
  }
  .ais-info {
    color: ${C.gray};
  }
  .ais-Snippet {
    color: ${C.gray};
  }
`

export const PoweredBy = () => (
  <span css="font-size: 0.6em; text-align: end; padding: 0;">
    <span css="opacity: 0.5;">
      {" "}
      Powered by{` `}
      <a href="https://algolia.com">
        <Algolia size="1em" /> Algolia
      </a>
    </span>
  </span>
)
