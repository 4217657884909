import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

function SEO({ description, meta, image: metaImage, title }) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              author
              description
              siteUrl
              socialImageUrl
            }
          }
        }
      `}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        let image = {
          src: data.site.siteMetadata.socialImageUrl
        }
        if (metaImage) {
          image = metaImage
        }
        return (
          <Helmet
            htmlAttributes={{
              lang: "en"
            }}
            title={title}
            meta={[
              {
                name: "description",
                content: metaDescription
              },
              {
                property: "og:title",
                content: title
              },
              {
                property: "og:description",
                content: metaDescription
              },
              {
                name: "twitter:creator",
                content: data.site.siteMetadata.author
              },
              {
                name: "twitter:site",
                content: data.site.siteMetadata.author
              },
              {
                name: "twitter:title",
                content: title
              },
              {
                name: "twitter:description",
                content: metaDescription
              }
            ]
              .concat(
                image
                  ? [
                      {
                        property: "og:image",
                        content: image.src
                      },
                      {
                        property: "og:image:width",
                        content: image.width
                      },
                      {
                        property: "og:image:height",
                        content: image.height
                      },
                      {
                        property: "twitter:image",
                        content: image.src
                      },
                      {
                        name: "twitter:card",
                        content: "summary_large_image"
                      }
                    ]
                  : [
                      {
                        name: "twitter:card",
                        content: "summary"
                      }
                    ]
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  meta: []
}

//look here for the way the SEO component is handled
//width and height is not a must

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }),
  meta: PropTypes.array,
  title: PropTypes.string.isRequired
}

export default SEO