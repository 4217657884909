//solve bug for which the locale in the homepage is simplified and does not allow to Localize the Link of the header

const reverseTransformLocale = locale => {
    let localeVar;
    if (locale === "en-US" || locale === "en") {
      localeVar = "en-US";
    } else if (locale === "zh-CN" || locale === "cn") {
      localeVar = "zh-CN";
    }

    return localeVar;
  };



  export default reverseTransformLocale