import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
// import { useTranslation } from "react-i18next"

import { SearchIcon, Form, Input } from "../styles"

export default connectSearchBox(({ refine, ...rest }) => {
  // const { t, i18n } = useTranslation()
  return (
    <Form action="" method="get">
      <Input
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={e => refine(e.target.value)}
        {...rest}
      />
      <SearchIcon />
    </Form>
  )
})
