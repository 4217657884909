import React, { Fragment } from "react"
import { Highlight, Snippet } from "react-instantsearch-dom"
import { Link } from "gatsby"
import { Calendar } from "styled-icons/octicons/Calendar"
// import { Tags } from "styled-icons/fa-solid/Tags"

// export const PageHit = clickHandler => ({ hit }) => (
//   <div>
//     <Link to={hit.slug} onClick={clickHandler}>
//       <h4>
//         <Highlight attribute="title" hit={hit} tagName="mark" />
//       </h4>
//     </Link>
//     <Snippet attribute="og_description" hit={hit} tagName="mark" />
//   </div>
// )

export const BlogPostHit = clickHandler => ({ hit }) => {
  return (
    <div>
      <Link to={`blog/${hit.slug}`} onClick={clickHandler}>
        <h4>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
      </Link>
      <div className="ais-info">
        <Calendar size="1em" />
        &nbsp;
        <Highlight attribute="date" hit={hit} tagName="mark" />
      </div>
      <Snippet attribute="og_description" hit={hit} tagName="mark" />
    </div>
  )
}

export const NewsPostHit = clickHandler => ({ hit }) => {
  return (
    <div>
      <Link to={`news/${hit.slug}`} onClick={clickHandler}>
        <h4>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
      </Link>
      <div className="ais-info">
        <Calendar size="1em" />
        &nbsp;
        <Highlight attribute="date" hit={hit} tagName="mark" />
      </div>
      <Snippet attribute="og_description" hit={hit} tagName="mark" />
    </div>
  )
}
