

//common

const adLink = "https://eosinfra.io"


export {
    adLink
  }

