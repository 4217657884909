import React from "react";
import styled from "styled-components";
import C from "../../colors";
import PropTypes from "prop-types";
import reverseTransformLocale from "../components/LocaleTransform/reverseTransformLocale";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import Search from "../components/Search";
import { Location } from "@reach/router";
import { FormattedMessage, IntlProvider} from "react-intl";
import LanguageSwitcher from "./LanguageSwitcher";
import LocalizedLink from "../components/localizedLink";
import 'babel-polyfill'
import '@formatjs/intl-pluralrules/polyfill'; 
import '@formatjs/intl-unified-numberformat/polyfill'
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/zh';

import 'core-js' ;

import {UnifiedNumberFormat} from '@formatjs/intl-unified-numberformat';

// import '@formatjs/intl-pluralrules/polyfill-locales';
// import '@formatjs/intl-relativetimeformat/polyfill-locales';
// import 'babel-polyfill'

// Messages
import en from "../locales/en-US";
import cn from "../locales/cn";

import IntlRelativeTimeFormat from '@formatjs/intl-relativetimeformat';
// locale-data for zh
IntlRelativeTimeFormat.__addLocaleData(
  require('@formatjs/intl-relativetimeformat/dist/locale-data/zh.json')
);
 
// locale-data for zh
IntlRelativeTimeFormat.__addLocaleData(
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en.json')
);


UnifiedNumberFormat.__addLocaleData(
  require('@formatjs/intl-unified-numberformat/dist/locale-data/zh.json') // locale-data for zh
);
UnifiedNumberFormat.__addLocaleData(
  require('@formatjs/intl-unified-numberformat/dist/locale-data/en.json') // locale-data for en
);
 
new UnifiedNumberFormat('zh', {
  style: 'unit',
  unit: 'bit',
  unitDisplay: 'long',
}).format(1000); // 1,000比特


// initialize some things
const messages = { en, cn };


const searchIndices = [
  { name: `EOSGo_Blog_prod`, title: `BLOG POSTS`, hitComp: `BlogPostHit` },
  { name: `EOSGo_News_prod`, title: `NEWS POSTS`, hitComp: `NewsPostHit` }
];

const Page = styled.div`
  color: ${C.gray};
  font-size: 14px;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";


//temporary to avoid messy mobile version.
    .marketcap-link{
      @media (max-width: 450px) {
        display: none !important;
      }
    }

  .container {
    margin: 0 auto;
    width: 96%;
    max-width: 1200px;
  }
  .header {
    display: flex;
    margin: 50px 0 30px;
    @media (max-width: 600px) {
      margin-top: 20px;
      margin-bottom: 08px;
    }
    img {
      display: inline-block;
      margin-right: 30px;
    }
    .header-left,
    .header-right {
      width: 50%;
    }
    .header-right {
      text-align: right;
      margin-top: 5px;
      a {
        
      }

      @media (max-width: 600px) {
        margin: 40px 0;
      }
    }
    a {
      color: ${C.dark};
      margin-right: 20px;
      font-weight: bold;
      margin-top: 5px;
      display: inline-block;
      vertical-align: top;

      &.active {
        // text-decoration: underline;
        border-bottom: 2px solid;
      }
      &:hover {
        text-decoration: none;
      }
    }

    .logo-link {
      cursor: pointer;
    }
  }
  .notice {
    margin-bottom: 30px;
    background: ${C.gray};
    border-radius: 4px;
    color: white;
    padding: 12px 18px;
    strong {
      font-weight: 600;
    }
    a {
      color: white;
      text-decoration: underline;
      &:hover {
        opacity: 0.75;
      }
    }
  }
  @media (max-width: 600px) {
    h1 {
      font-size: 2rem;
    }
  }

  @media (max-width: 850px) {
    .tg-badge {
      position: fixed;
      z-index: 1000;
      bottom: 4rem;
      right: 16px;
      background: #08c;
      padding: 8px 15px 8px 8px;
      border-radius: 40px;
      transition: all 0.1s;
      cursor: pointer;
      line-height: 1;
      display: block;
      color: #fff;
      font-weight: 700;
      &:hover {
        text-decoration: none;
        background: #19d;
      }
      img {
        margin-right: 10px;
        width: 22px;
        height: 22px;
        border: 1px solid #6be;
        border-radius: 1000px;
      }
      span,
      img {
        vertical-align: middle;
      }
    }
  }

  .title {
    color: ${C.gray};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .row-flex {
    display: flex;
    margin: 0 -6px;
    flex-wrap: wrap;
    margin-bottom: 32px;
    & > * {
      margin: 0 6px;
      margin-bottom: 12px;
    }
  }

  footer {
    margin: 38px 0 100px 0;
    border-top: 1px solid ${C.light};
    padding: 10px 0;
    padding-bottom: 0px;
    margin-bottom: 30px;
    bottom: 0px;

    .general-info-link {
      float: right;
    }
  }

  @media (min-width: 850px) {
    .tg-badge {
      position: fixed;
      z-index: 1000;
      bottom: 2rem;
      right: 16px;
      background: #08c;
      padding: 8px 15px 8px 8px;
      border-radius: 40px;
      transition: all 0.1s;
      cursor: pointer;
      line-height: 1;
      display: block;
      color: #fff;
      font-weight: 700;
      &:hover {
        text-decoration: none;
        background: #19d;
      }
      img {
        margin-right: 10px;
        width: 22px;
        height: 22px;
        border: 1px solid #6be;
        border-radius: 1000px;
      }
      span,
      img {
        vertical-align: middle;
      }
    }
  }
`;

const currentLocale = pathname => {
  let result
  if (pathname.search("cn") >=0 && pathname.search("cn") <= 3){
    result = 'cn'
  } else {
    result = 'en'
  }
  return result 
}

const Layout = ({  children, contentfulID }) => {

  return (
    <Page>
       <Location>
      {({ location: { pathname } }) => (
      <IntlProvider
        locale={currentLocale(pathname)}
        messages={messages[reverseTransformLocale(currentLocale(pathname))]}
      >
        <Helmet>
          {/* <title> { 'EOS GO - ' + (props.title || 'Market Cap') } </title> */}
        </Helmet>
        {console.log('THIS IS THE LOCALE', currentLocale(pathname))}
        <div className="container">
          <div className="header">
            <div className="header-left">
              <Link to="/">
                <img
                  className="logo-link"
                  src="/img/logo.png"
                  alt="EOS Go Logo"
                  style={{ height: 40 }}
                />
              </Link>
              <Link to="/" activeClassName="active" className="marketcap-link">
                Market CAP 
              </Link>
              <Link to="/blog" activeClassName="active">
                Blog
              </Link>
              <Link to="/news" activeClassName="active">
                News
              </Link>
{/* 
              <LanguageSwitcher 
              className="language-switcher"
              contentfulID={contentfulID}
              locale={currentLocale(pathname)}
              /> */}
            </div>
            <div className="header-right">
            

              <Search collapse indices={searchIndices} />

            </div>
          </div>

          {children}

          {/* <footer>
            <FormattedMessage id="footer-maintained" />{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/eosasia"
            >
              <FormattedMessage id="footer-owner" />
            </a>
            <FormattedMessage id="footer-token-price-source" />{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://marketcap.one/"
            >
              <FormattedMessage id="footer-api-source" />
            </a> */}
            .
            {/* Token prices from <a target="_blank" href="https://newdex.io/">NEWDEX</a>. */}
            {/* <a className="general-info-link" href="/info">
              {" "}
              <FormattedMessage id="footer-general-info" />
            </a>
          </footer> */}
          <a
            href="https://t.me/eosgo"
            target="_blank"
            rel="noopener noreferrer"
            className="tg-badge"
          >
            <img
              alt="telegram_share"
              height="18"
              width="18"
              src="/img/telegram.png"
            />
            <span>Telegram</span>
          </a>
        </div>
      </IntlProvider>
      )}
      </Location>
    </Page>
  );
};

Layout.propTypes = {
  INTlocale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Layout;
