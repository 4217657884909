module.exports = {
    'en-US': {
      path: "en",
      locale: "English",
      localeShort: "en-US",
      default: true
    },
    'zh-CN': {
      path: "cn",
      locale: "中文",
      localeShort: "zh-CN"
    }
  };
  