import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from "@reach/router";
import { StaticQuery, graphql } from "gatsby";
import simpleTransformLocale from "../components/LocaleTransform/simpleTransformLocale"

import { Link } from "gatsby";
import locales from "../locales";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  background-color: transparent;
  box-shadow: none;

  svg {
    font-size: 22px;
    margin-left: 60px;
  }
`;
const toPage = pathname => pathname.split(/\/[a-z]{2}\//).pop();



const ContentfulKick = (data, pathname, locales, key, contentfulID, locale) => {
  let BlogPosts = data.blog.edges
  let InfoPosts = data.info.edges
  let NewsPosts = data.news.edges

  //regex checks if there is any content after /blog/ or /info/ or /news/ . Learn more: https://blog.bitsrc.io/a-beginners-guide-to-regular-expressions-regex-in-javascript-9c58feb27eb4
  let regex = /\/(blog|news|info)\/./

  //this function understands whats the location of the site so the URL can be dynamic and applied to all pages
  const getLocation = () => {
    let location 
    if (pathname.search("blog") >= 1 && pathname.search("blog") <= 7){
      location = "blog"
    } else if (pathname.search("news") >= 1 && pathname.search("news") <= 7){
      location = "news"
    } else if (pathname.search("info") >= 1 && pathname.search("info") <= 7){
      location = "info"
    }
    return location
  }

  //this function assigns the correct array to use
  const getCorrectData = () => {
    let correctData 
    if (getLocation() === "blog"){
      correctData = BlogPosts
    } else if (getLocation() === "news"){
      correctData = NewsPosts
    } else if (getLocation() === "info"){
      correctData = InfoPosts
    }
    return correctData
  }

  if (regex.test(pathname) ) {
    let lengthOfArray = getCorrectData().length

    if (locale === 'en'){
      
    for (let i=0; i<lengthOfArray; i++){
      if (getCorrectData()[i].node.contentful_id === contentfulID && getCorrectData()[i].node.node_locale === "zh-CN" && getCorrectData()[i].node.slug !== null) {
        //if its a blogpost its going to find the counterpart and read the slug if the slug is not null (therefore it must be available)

        let localizedPath = `/${getLocation()}/${getCorrectData()[i].node.slug}`
        return <Link
            key={locales[key].locale}
            ml={3}
            color={key === locale ? "red" : "white"}
            to={
              locales[key].default
                ? `/${toPage(pathname)}`
                : `/${locales[key].path}/${toPage(localizedPath)}`
            }
          >
          {locales[key].locale}
          </Link>
      } else if (getCorrectData()[i].node.contentful_id === contentfulID && getCorrectData()[i].node.node_locale === "zh-CN" && getCorrectData()[i].node.slug === null) {
        // if the slug is null in the counterpart (there is no translation; its going to the translated homepage)
        let localizedPath = `/${getLocation()}/`
        return <Link
            key={locales[key].locale}
            ml={3}
            color={key === locale ? "red" : "white"}
            to={
              locales[key].default
                ? `/${toPage(localizedPath)}`
                : `/${locales[key].path}${localizedPath}`
            }
          >

          {locales[key].locale}
          </Link>
      }
    } 
  } else if (locale === 'cn'){
    for (let i=0; i<lengthOfArray; i++){
      if (getCorrectData()[i].node.contentful_id === contentfulID && getCorrectData()[i].node.node_locale === "en-US" && getCorrectData()[i].node.slug !== null) {
        //if its a blogpost its going to find the counterpart and read the slug if the slug is not null (therefore it must be available)

        let localizedPath = `/${getLocation()}/${getCorrectData()[i].node.slug}`
        return <Link
            key={locales[key].locale}
            ml={3}
            color={key === locale ? "red" : "white"}
            to={
              locales[key].default
                ? `/${toPage(localizedPath)}`
                : `/${locales[key].path}/${toPage(localizedPath)}`
            }
          >
          {locales[key].locale}
          </Link>
      } else if (getCorrectData()[i].node.contentful_id === contentfulID && getCorrectData()[i].node.node_locale === "en-US" && getCorrectData()[i].node.slug === null) {
        // if the slug is null in the counterpart (there is no translation; its going to the translated homepage)
        let localizedPath = `/${getLocation()}/`
        return <Link
            key={locales[key].locale}
            ml={3}
            color={key === locale ? "red" : "white"}
            to={
              locales[key].default
                ? `/${toPage(localizedPath)}`
                : `/${locales[key].path}`
            }
          >
          {locales[key].locale}
          </Link>
      }
    } 
  }
  }  else  {
    return <Link
            key={locales[key].locale}
            ml={3}
            color={key === locale ? "red" : "white"}
            to={
              locales[key].default
                ? `/${toPage(pathname)}`
                : `/${locales[key].path}/${toPage(pathname)}`
            }
          >
            {locales[key].locale}
          </Link>
  }


}


class LanguageSwitcher extends React.Component {
// const LanguageSwitcher = ({ intl: { locale } }) =>{

render(){

let locale= simpleTransformLocale(this.props.locale)
let contentfulID = this.props.contentfulID



return (
  <StaticQuery
        query={Posts}
        render={data => (
  <Location>
    {({ location: { pathname } }) => (
      <Nav>
        <span>
          {/* <Globe /> */}
        </span>
        {Object.keys(locales).map(key => (
          ContentfulKick(data, pathname, locales, key, contentfulID, locale)
        ))}

      </Nav>
    )}
  </Location>
  )}
  />
)
;
          }
        }

LanguageSwitcher.propTypes = {
  locale: PropTypes.string.isRequired, 
  contentfulID: PropTypes.string
};

export default injectIntl(LanguageSwitcher);


const Posts = graphql`
  query {
    blog: allContentfulPost {
      edges {
        node {
          slug
          contentful_id
          node_locale
        }
      }
    }
    info: allContentfulSeoPost {
      edges {
        node {
          slug
          contentful_id
          node_locale
        }
      }
    }
    news: allContentfulNews {
      edges {
        node {
          slug
          contentful_id
          node_locale
        }
      }
    }
  }
`;